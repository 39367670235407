import React, { useContext, useEffect, useState } from "react";
import { Link, useFetcher, useNavigate, useParams } from "react-router-dom";
import "./placesPage.scss";
import axios from "axios";
import AddNewPlace from "../addNewPlace/AddNewOrder";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CreateOrderButton from "../../components/createOderButton/CreateOrderButton";
import { UserContext } from "../../UserContext";
import { recomposeColor } from "@mui/material";

const PlacesPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [places, setPlaces] = useState([]);
  const userDepartment = user.department;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userDepartment === "Sales") {
          const response = await axios.get("/user-places");
          const updatedPlaces = response.data.map((place) => ({
            ...place,
            id: place.id,
          }));
          setPlaces(updatedPlaces);
        } else {
          const allResponse = await axios.get("/getAllOrders");
          const updatedPlaces = allResponse.data.map((place) => ({
            ...place,
            id: place.id,
          }));
          setPlaces(updatedPlaces);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setPlaces("");

    fetchData();
  }, [userDepartment]);

  const handleCellClick = (params) => {
    const placesId = params.row.id;
    const url = `${placesId}`;
    navigate(url);
  };

  // Define columns for the DataGrid
  const columns = [
    { field: "skuId", headerName: "Order No", width: 200 },
    { field: "client", headerName: "Client" },
    { field: "confirmationStatus", headerName: "Conf.Status" },
    { field: "salesETA", headerName: "Container Type" },
    {
      field: "country",
      headerName: "Country",
    },
    {
      field: "financialConfirmation",
      headerName: "Incoterms",
    },
    {
      field: "allocation",
      headerName: "Allocation",
    },
    {
      field: "procurementETA",
      headerName: "Procurement ETA",
    },
    {
      field: "productionETA",
      headerName: "Production ETA",
    },
    {
      field: "containerType",
      headerName: "Container Type",
    },
    {
      field: "dispatchETA",
      headerName: "Dispatch ETA",
    },
    {
      field: "desitinationETA",
      headerName: "Desitination ETA",
    },

    // Add more columns as needed
  ];
  // console.log(places.length > 0 && places.filter((item) => !item.client));

  const removeUnsave = async () => {
    const itemsWithoutClient =
      places.length > 0 && places.filter((item) => !item.client);

    if (itemsWithoutClient.length > 0) {
      const orderNos = itemsWithoutClient.map((item) => item.id);
      // console.log(orderNos);

      const cancelOrder = async (orderNo) => {
        const list = { orderNo };
        try {
          await Promise.all([
            axios.post(`/cancel-order/${orderNo}`, list),
            // axios.post(`/cancel-orderItems/${orderNo}`, list),
          ]);
          // console.log(`Order ${orderNo} cancelled successfully.`);
        } catch (error) {
          // console.log(`Error cancelling order ${orderNo}:`, error);
        }
      };

      for (const orderNo of orderNos) {
        await cancelOrder(orderNo);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    removeUnsave();
  }, [places]);

  return (
    <div className="places">
      <div className="__mw">
        <div className="__place_in">
          {userDepartment === "Sales" && <CreateOrderButton />}

          <div className="all_place">
            <DataGrid
              key={places.id}
              onCellClick={handleCellClick}
              rows={places}
              columns={columns}
              pageSize={5}
              over
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: {
                    //pageSize:
                  },
                },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlacesPage;

import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Navbar from "./components/header/Navbar";
import Footer from "./components/footer/Footer";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import axios from "axios";
import AccountPage from "./pages/account/AccountPage";
import SingalPlace from "./pages/singalPlace/SingalPlace";
import AddNewOrder from "./pages/addNewPlace/AddNewOrder";
import GetNavdata from "./components/getNavData/GetNavdata";
import UploadImage from "./pages/uploadImage/UploadImage";
import PlacesPage from "./pages/places/PlacesPage";
import { UserContext, UserContextProvider } from "./UserContext";
import PrivateRoutes from "./components/PrivateRoutes.js";

// axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = "https://spw-api.notionsgroup.com";
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Navbar />
        <Routes>
          {/* <Route exact path="/" element={<AccountPage />} /> */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route element={<PrivateRoutes />}>
            <Route exact path="/account" element={<AccountPage />} />
            <Route exact path="/account/:subpage?" element={<AccountPage />} />
            <Route
              exact
              path="/account/:subpage/:action"
              element={<AccountPage />}
            />
            <Route exact path="/account/orders/:id" element={<AddNewOrder />} />
            <Route exact path="/account/order/:id" element={<AddNewOrder />} />
            <Route exact path="/place/:id" element={<SingalPlace />} />
            <Route exact path="/getdata" element={<GetNavdata />} />
            <Route exact path="/upload" element={<UploadImage />} />
            <Route exact path="/account/orders" element={<PlacesPage />} />
          </Route>
          <Route exact path="*" element={<Login />} />
          {/* <Route exact path="/account/update" element={<OrderUpdate />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default App;

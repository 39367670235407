import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Itinerary from "../../components/itinerary/Itinerary";
import "./addNewOrder.scss";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ItemForm from "../../components/itemForm/ItemForm";
import { Hidden, ListItemButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./itemForm.css";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { UserContext } from "../../UserContext";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//import Button from "@mui/material/Button";
import { MdFormatListBulleted } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import { FcHighPriority } from "react-icons/fc";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { GoCheckCircleFill } from "react-icons/go";
import { UserContext } from "../../UserContext";

const AddNewOrder = () => {
  const { user } = useContext(UserContext);

  const { id } = useParams();
  const orderNo = id;

  const [clientNo, setClientNo] = useState("");
  const [client, setClient] = useState(""); // ! ok
  const [itemTotalItem, setItemTotalItem] = useState([]);
  //const [country, setCountry] = useState("");
  const [incoterms, setIncoterms] = useState(""); // !ok
  const [confirmationStatus, setConfirmationStatus] = useState(""); // ! ok
  const [containerType, setContainerType] = useState(""); // ! ok
  const [salesETA, setSalesETA] = useState(""); // ! ok
  const [dispatchETA, setDispatchETA] = useState(""); // !ok
  const [desitinationETA, setDesitinationETA] = useState(""); // ! ok
  const [financialConfirmation, setFinancialConfirmation] = useState(""); // ! ok
  const [financialComments, setFinancialComments] = useState(""); // ! ok
  const [allocation, setAllocation] = useState("");
  const [warehouseComments, setWarehouseComments] = useState(""); // !ok
  const [procurementETA, setProcurementETA] = useState(""); // ! ok
  const [procurementComments, setProcurementComments] = useState("");
  const [productionETA, setProductionETA] = useState(""); // ! ok
  const [productionComments, setProductionComments] = useState(""); // ! ok
  const [logisticsComments, setLogisticsComments] = useState(""); // ! ok
  const [selectedItem, setSelectedItem] = useState([]);
  const [itemCost, setItemCost] = useState("");
  const [tpCost, setTpCost] = useState("");

  const [itemCode, setItemCode] = useState("");
  const [itemDescription, setItemDescription] = useState([]);

  const [saveItem, setSaveItem] = useState([]);
  const [conformStatus, setConformStatus] = useState([]);
  const [listItemNumber, setListItemNumber] = useState("");
  const [allOrderItem, setAllOrderItem] = useState([]);

  const [finalStatus, setFinalStatus] = useState("");

  const [amount, setAmount] = useState(0);
  const [precentage, setPrecentage] = useState("");
  const [profit, setProfit] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [itemCostSelectEntity, setItemCostSelectEntity] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [spwPriceItem, setSpwPriceItem] = useState([]);
  const [createdItem, setCreatedItem] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  // const [quantities, setQuantities] = useState(spwPriceItem.map(() => 1));
  const initialQuantities = itemTotalItem.map(() => 0);
  const [quantities, setQuantities] = useState(initialQuantities);
  const [previousQuantity, setPreviousQuantity] = useState(undefined);
  const [orderDate, setOrderDate] = useState("");
  const [listingCost, setListingCost] = useState(0);
  const [rabate, setRabate] = useState(0);
  const [posm, setPosm] = useState(0);
  const [rental, setRental] = useState(0);
  const [sampling, setSampling] = useState(0);
  const [foc, setFoc] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [others, setOthers] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const extraExpenses = () => {
    const total =
      (parseFloat(listingCost) || 0) +
      (parseFloat(rabate) || 0) +
      (parseFloat(posm) || 0) +
      (parseFloat(rental) || 0) +
      (parseFloat(sampling) || 0) +
      (parseFloat(foc) || 0) +
      (parseFloat(discount) || 0) +
      (parseFloat(others) || 0);
    return isNaN(total) ? 0 : total;
  };

  useEffect(() => {
    const handleCalculateTotal = () => {
      const totalValue = extraExpenses();
      setTotalExpenses(totalValue);
    };
    handleCalculateTotal();
  }, [listingCost, rabate, posm, rental, sampling, foc, discount, others]);

  //console.log(totalExpenses);

  const handleQuantityChange = (index, newValue) => {
    setPreviousQuantity(newValue);

    const newQuantities = [...quantities];
    newQuantities[index] = newValue;
    setQuantities(newQuantities);
  };
  // console.log(quantities);
  const quantity =
    Array.isArray(quantities) && quantities.length > 0
      ? quantities[quantities.length - 1]
      : undefined;

  // console.log("previousQuantity", previousQuantity);
  // console.log("quantity", quantity);
  ///CMB calculater

  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [cbm, setCBM] = useState(null);

  const [msg, setMsg] = useState("");

  // create items
  const [itemId, setItemId] = useState("");

  //get Total Item cost

  const [selectedItemId, setSelectedItemId] = useState(null);

  // console.log(itemTotalItem);

  // item save in database

  const [itemNumber, setItemNumber] = useState("");
  const [description, setDescription] = useState("");
  const [imageSrc, setImageSrc] = useState("");

  const handleItemNumberChange = (e) => {
    setItemNumber(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // ! pograss bar
  const [progress, setProgress] = useState(0);

  const [isShowMoreA, setIsShowMoreA] = useState(true);
  const [isShowMoreB, setIsShowMoreB] = useState(true);
  const [isShowMoreC, setIsShowMoreC] = useState(true);
  const [isShowMoreD, setIsShowMoreD] = useState(true);
  const [isShowMoreE, setIsShowMoreE] = useState(true);
  const [isShowMoreF, setIsShowMoreF] = useState(true);

  const toggleReadMoreLessA = (e) => {
    e.preventDefault();
    setIsShowMoreA(!isShowMoreA);
  };
  const toggleReadMoreLessB = (e) => {
    e.preventDefault();
    setIsShowMoreB(!isShowMoreB);
  };
  const toggleReadMoreLessC = (e) => {
    e.preventDefault();
    setIsShowMoreC(!isShowMoreC);
  };
  const toggleReadMoreLessD = (e) => {
    e.preventDefault();
    setIsShowMoreD(!isShowMoreD);
  };
  const toggleReadMoreLessE = (e) => {
    e.preventDefault();
    setIsShowMoreE(!isShowMoreE);
  };
  const toggleReadMoreLessF = (e) => {
    e.preventDefault();
    setIsShowMoreF(!isShowMoreF);
  };

  // const clientData = async () => {
  //   const salesperson_same = 'La Ronda'
  //   try {
  //     await axios.get("/spwcus", salesperson_same).then((response) => {
  //       setSelectedItem(response.data);
  //     });
  //   } catch (error) {}
  // };

  //Get Items Total

  const clientData = async (e) => {
    e.preventDefault();
    const salesperson_name = `${user.name}`;
    try {
      await axios
        .post("/spwcuslist", { salesperson_name: salesperson_name })
        .then((response) => {
          setSelectedItem(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const arrayItem = client ? client.split(",") : [];
  const sales_code = arrayItem[1];
  const country = arrayItem[2];

  const navigate = useNavigate();

  useEffect(() => {
    if (!orderNo) {
      return;
    }
    axios.get("/places/" + orderNo).then((response) => {
      const { data } = response;
      //setId(data.id)
      setClient(data?.client);
      //setCountry(data.country);
      //setSales_code(data.sales_code);
      setIncoterms(data?.incoterms);
      setConfirmationStatus(data?.confirmationStatus);
      setContainerType(data?.containerType);
      setSalesETA(data?.salesETA);
      setDispatchETA(data?.dispatchETA);
      setDesitinationETA(data?.desitinationETA);
      setFinancialConfirmation(data?.financialConfirmation);
      setFinancialComments(data?.financialComments);
      setAllocation(data?.allocation);
      setOrderDate(data?.orderDate);
      setWarehouseComments(data?.warehouseComments);
      setProcurementETA(data?.procurementETA);
      setProcurementComments(data?.procurementComments);
      setProductionETA(data?.productionETA);
      setProductionComments(data?.productionComments);
      setLogisticsComments(data?.logisticsComments);
    });
  }, [orderNo]);

  const saveOrder = async (e) => {
    e.preventDefault();

    const data = {
      id,
      sales_code,
      client,
      country,
      incoterms,
      confirmationStatus,
      containerType,
      salesETA,
      dispatchETA,
      desitinationETA,
      financialConfirmation,
      financialComments,
      allocation,
      warehouseComments,
      procurementETA,
      procurementComments,
      productionETA,
      productionComments,
      logisticsComments,
      listingCost,
      rabate,
      posm,
      rental,
      sampling,
      foc,
      discount,
      others,
      totalExpenses,
      totalPI,
      totalGrossProfit,
      totalGrossProfitPracentage,
      totalNP,
      totalNpPracentageValue,
      totalCTN,
      totalCMBRound,
      totalTPAll,
      finalStatus,
      orderDate,
    };

    try {
      await axios.post("/add-order/" + id, data);
      navigate("/account/orders");
      //  if (id) {
      //    //update

      //    await axios.put("/places", { id, ...data });
      //    navigate("/account/orders");
      //  } else {
      //    //new place
      //    await axios.post("/places", data);
      //    navigate("/account/orders");
      //  }
    } catch (error) {
      console.log(error);
    }
  };

  const itemData = async () => {
    await axios
      .get("/spwitem")

      .then((response) => {
        const fetchedOptions = response.data;
        setSelectedValue(fetchedOptions);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const itemsDetails = itemCode ? itemCode.split(",") : [];
  const item_no = itemsDetails[0];
  const unit_of_measure_code = itemsDetails[2];
  const cost = itemsDetails[3];

  // ! do not touch start
  useEffect(() => {
    if (!client) {
      return;
    } else {
      const findPrice = async () => {
        const dataList = {
          sales_code,
        };
        try {
          await axios.post("/spwpriceItem", dataList).then((response) => {
            setSpwPriceItem(response.data);
          });
        } catch (error) {
          console.log("data not post");
        }
      };
      findPrice();
    }
  }, [client]);

  // ! do not touch End

  // Item form value
  const unit_dec = unitPrice.split(",");
  // console.log(unit_dec);
  const itemdec = unit_dec[0];
  //const itemNo = unit_dec[1];
  const unitOfMe = unit_dec[2];
  const unitOfPrice = unit_dec[3];
  const currency = unit_dec[4];
  const perUnitPcs = unit_dec[5];
  let findExchRateAmount = unit_dec[6];
  let boxWidth = unit_dec[7];
  let boxLenght = unit_dec[8];
  let boxHight = unit_dec[9];
  const imagesSrc = unit_dec[10];

  // todo select item info

  const [itemInfo, setItemInfo] = useState([]);

  // Item form value
  // const selectItemInfo = itemInfo.split(",");
  // console.log(selectItemInfo);
  // const itemdec = selectItemInfo[0];
  // const itemNo = selectItemInfo[1];
  // const unitOfMe = selectItemInfo[2];
  // const unitOfPrice = selectItemInfo[3];
  // const currency = selectItemInfo[4];
  // const perUnitPcs = selectItemInfo[5];
  // let findExchRateAmount = selectItemInfo[6];
  // let boxWidth = selectItemInfo[7];
  // let boxLenght = selectItemInfo[8];
  // let boxHight = selectItemInfo[9];
  // const imagesSrc = selectItemInfo[10];

  const findcurrency = () => {
    if (
      findExchRateAmount === 0 ||
      findExchRateAmount === "" ||
      findExchRateAmount === null ||
      findExchRateAmount === undefined
    ) {
      findExchRateAmount = 1;
    }
    return findExchRateAmount;
  };

  let exchRateAmount = findcurrency();

  // const totalAmount = () => {
  //   const total = unitOfPrice * quantity;
  //   setAmount(total)
  // }

  //------- Add new Item ----------//

  const addNewItem = async (e) => {
    e.preventDefault();

    const itemList = {
      orderNo,
      //itemNo,
      itemdec,
      unitOfMe,
      unitOfPrice,
      amount,
      quantity,
      cbm,
      // height,
      // width,
      // length,
      // max_relational_exch_rate_amount,
      // qty_per_unit_of_measure,
      // unit_of_measure_code,
      // unit_price,
      // currency_code,
    };

    // Check if itemNo already exists
    // const itemAlreadyExists = createdItem.some(
    //   (item) => item.itemNo === itemNo && item.orderNo === orderNo
    // );

    // if (itemAlreadyExists) {
    //   console.log(
    //     "Item with the same itemNo already exists. Please choose a different itemNo."
    //   );
    //   return;
    // }

    try {
      await axios.post("/createItem", itemList);
      setCreatedItem((prevItems) => [...prevItems, itemList]);
    } catch (error) {
      console.log(error);
    }
    setForceRender((prev) => !prev);
  };

  // Calculate Total Price

  useEffect(() => {
    const totalCost = () => {
      const total = quantity * unitOfPrice;
      setAmount(total);
    };
    totalCost();
  }, [quantity]);

  useEffect(() => {
    const getItems = async () => {
      const list = {
        orderNo,
      };
      try {
        await axios.post("/findtotal", list).then((response) => {
          setItemTotalItem(response.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getItems();
  }, [createdItem, forceRender, quantity]);

  // Assuming itemTotalItem is an array of objects with the 'amount' property
  const totalAmount = itemTotalItem.reduce((sum, item) => sum + item.amount, 0);

  // total quntaty
  const totalQut = itemTotalItem.reduce(
    (sum, item) => sum + parseInt(item.quantity),
    0
  );
  // total CMB
  const totalCMB = itemTotalItem.reduce(
    (sum, item) => sum + parseFloat(item.cbm),
    0
  );

  const findCurrency = () => {
    return currency || "AED";
  };

  // const exchRateAmountValue = () => {
  //   console.log(exchRateAmount);
  //   if (exchRateAmount === 0){
  //     const inAed = exchRateAmount(1)
  //     console.log(inAed);
  //   }
  //   return exchRateAmount;
  // };
  // console.log(exchRateAmountValue());

  // Amount in AED
  const amountInAED = () => {
    const aedAmount = exchRateAmount * amount;
    return aedAmount;
  };

  const AmountInAED = amountInAED().toFixed(0);

  //Find pcs
  const totalPcs = () => {
    const pcs = perUnitPcs * quantity;
    return pcs;
  };

  //find Tp Cost

  const findTpCost = () => {
    // get cost
    const cost = itemCostSelectEntity?.unit_Cost * totalPcs();
    return cost.toFixed(0);
  };

  // ! delete item from list
  //Item delete

  const deleteItem = async (itemId) => {
    setSelectedItemId(itemId);
    const list = {
      itemId,
    };

    try {
      await axios.post(`/deleteitem`, list);
      console.log("Delete successful");
      setCreatedItem((prevItems) =>
        prevItems.filter((item) => item.orderNo !== itemId)
      );
    } catch (error) {
      console.error(error);
    }
  };
  //ProgressBar

  const appStyles = {
    width: 404,
    height: 200,
    marginLeft: 95,
  };

  useEffect(() => {
    setProgress(containerCMBTotal);
  }, [quantity, containerType, addNewItem, deleteItem]);

  const handleImageClick = (itemNo) => {
    // Do something with the selected itemNumber, e.g., set it in state or perform an action
    console.log(`Selected item number: ${itemNo}`);
  };

  // ! ---Select item from list and save in Database-------->>

  const [selectedItemNo, setSelectedItemNo] = useState(null);

  // console.log(selectedItemNo);

  const item_item_no = selectedItemNo?.item_no;
  const item_sales_code = selectedItemNo?.sales_code;
  const item_unit_price = selectedItemNo?.unit_price;
  const item_unit_of_measure_code = selectedItemNo?.unit_of_measure_code;
  const item_salesperson_code = selectedItemNo?.salesperson_code;
  const item_description = selectedItemNo?.description;
  const item_max_relational_exch_rate_amount =
    selectedItemNo?.max_relational_exch_rate_amount;
  const item_currency_code = selectedItemNo?.currency_code;
  const item_qty_per_unit_of_measure = selectedItemNo?.qty_per_unit_of_measure;
  const item_length = selectedItemNo?.length;
  const item_width = selectedItemNo?.width;
  const item_height = selectedItemNo?.height;
  const item_images = selectedItemNo?.images;

  const handleAddButtonClick = (productItemInfo) => {
    // Update the state with the selected item_no when the "Add" button is clicked.
    setSelectedItemNo(productItemInfo);
    // setListItemNumber(productItemInfo.item_no);
  };

  // todo add item in data base

  const saveItemDB = async (e) => {
    e.preventDefault();
    const itemList = {
      orderNo,
      item_item_no,
      item_sales_code,
      item_unit_price,
      item_unit_of_measure_code,
      item_salesperson_code,
      item_description,
      item_max_relational_exch_rate_amount,
      item_currency_code,
      item_qty_per_unit_of_measure,
      item_length,
      item_width,
      item_height,
      item_images,
      totalCBM,
      amountOriginalPrice,
    };

    // Check if itemNo already exists
    const itemAlreadyExists = createdItem.some(
      (item) => item.item_item_no === item_item_no && item.orderNo === orderNo
    );

    if (itemAlreadyExists) {
      console.log(
        "Item with the same itemNo already exists. Please choose a different itemNo."
      );
      return;
    }

    try {
      await axios.post("/createItem", itemList);
      setCreatedItem((prevItems) => [...prevItems, itemList]);
    } catch (error) {
      console.log(error);
    }
    setForceRender((prev) => !prev);
  };

  // const seeId = (e) => {
  //   e.preventDefault();
  //   saveItemDB();
  // };

  //css

  const styles = {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  };

  // const handleQuantity = (e) => {
  //   setQuantity(e.target.value);
  // };

  // ! get item number from list to find cost
  const getCostFromAPI = (listProductItemInfo) => {
    if (listProductItemInfo) {
      setListItemNumber(listProductItemInfo);
    } else {
      console.error("listProductItemInfo is null or undefined");
    }
  };

  // console.log(listItemNumber);
  // console.log(quantity);

  // todo update item in databse after calculation done

  const [totalCBM, setTotalCBM] = useState(0);
  const [amountOriginalPrice, setAmountOriginalPrice] = useState(0);
  const [valueAED, setValueInAED] = useState(0);
  const [totalTP, setTotalTP] = useState(0);
  const [totalLrCost, setTotalLrCost] = useState(0);
  const [grossProfit, setGrossProfit] = useState(0);
  const [grossProfitPracentage, setGrossProfitPracentage] = useState(0);
  const [error, setError] = useState(null);
  const [reUpdateItem, setReUpdateItem] = useState([]); // update data in database
  const [getReUpdateItem, setGetReUpdateItem] = useState([]);

  //! todo update item model in database

  useEffect(() => {
    const updateItem = async () => {
      const itemList = {
        listItemNumber,
        orderNo,
        totalCBM,
        amountOriginalPrice,
        valueAED,
        totalTP,
        totalLrCost,
        grossProfit,
        grossProfitPracentage,
        previousQuantity,
      };
      try {
        await axios
          .post(`/createItem/${listItemNumber}`, itemList, {
            withCredentials: true,
          })
          .then((response) => {
            setReUpdateItem(response.data);
          });

        setQuantities([0]);
      } catch (error) {
        console.log(error);
      }
    };
    if (quantity !== undefined) {
      updateItem();
    }
  }, [quantity]);

  // ! final total CBM

  const calCBM = () => {
    const calculatedCBM = (item_length * item_width * item_height) / 1000000;
    return parseFloat(calculatedCBM.toFixed(2));
  };

  useEffect(() => {
    const updateCBM = () => {
      const cbm = (calCBM() * quantity).toFixed(2);
      if (isNaN(cbm)) {
        return 0;
      }
      setTotalCBM(cbm);
    };
    updateCBM();
  }, [quantities]);

  // const calCBM = (item_length, item_width, item_height) => {
  //   const calculatedCBM = (item_length * item_width * item_height) / 1000000;
  //   return parseFloat(calculatedCBM.toFixed(0));
  // };

  // const updateCBM = () => {
  //   const totalCBMs = quantities.map((quantity, index) => {
  //     const cbm = (
  //       calCBM(item_length, item_width, item_height) * quantity
  //     ).toFixed(0);
  //     return cbm;
  //   });

  //   // Assuming you want to sum up the CBMs for all items
  //   const totalCBM = totalCBMs.reduce((acc, cbm) => acc + parseFloat(cbm), 0);

  //   setTotalCBM(totalCBM.toFixed(0));
  // };

  // useEffect(() => {
  //   updateCBM();
  // }, [quantities]);

  //console.log(totalCBM);

  // ! Final LR cost--------------

  const lrCost = () => {
    const cost = item_qty_per_unit_of_measure * quantity * itemCost?.unit_Cost;
    if (isNaN(cost)) {
      return 0;
    }

    return cost.toFixed(0);
  };

  useEffect(() => {
    const updateLrCost = () => {
      const cost = lrCost();
      setTotalLrCost(cost);
    };
    updateLrCost();
  }, [quantities]);

  //console.log(totalLrCost);

  // ! final TP ----------

  const calTp = () => {
    const tp =
      item_qty_per_unit_of_measure * quantity * itemCostSelectEntity?.unit_Cost;
    return tp;
  };

  useEffect(() => {
    const updateTP = () => {
      const tp = calTp().toFixed(0);
      if (isNaN(tp)) {
        return 0;
      }
      setTotalTP(tp);
    };
    updateTP();
  }, [quantities]);

  //console.log(totalTP);

  //! final value in AED

  const calValue = () => {
    const rateAmount =
      item_max_relational_exch_rate_amount === 0
        ? 1
        : item_max_relational_exch_rate_amount;

    const value = rateAmount * calAmount().toFixed(0);
    if (isNaN(value)) {
      return 0;
    }

    return value;
  };

  useEffect(() => {
    const updateValue = () => {
      const value = calValue().toFixed(0);
      setValueInAED(value);
    };

    updateValue();
  }, [quantities]);

  //console.log(valueAED);

  // ! final Amount

  const calAmount = () => {
    const total = quantity * item_unit_price;
    if (isNaN(total)) {
      return 0;
    }

    return total;
  };

  useEffect(() => {
    const updateAmount = () => {
      const total = calAmount().toFixed(2);
      if (isNaN(total)) {
        return 0;
      }
      setAmountOriginalPrice(total);
    };

    updateAmount();
  }, [quantities]);

  //console.log(amountOriginalPrice);

  // ! final Gross profit

  const itemProfit = () => {
    let totalProfit = calValue() - calTp();

    if (isNaN(totalProfit)) {
      totalProfit = 0;
    }
    return totalProfit.toFixed(0);
  };

  useEffect(() => {
    const updateGrossProfit = () => {
      const totalProfit = itemProfit();

      setGrossProfit(totalProfit);
    };
    updateGrossProfit();
  }, [quantities]);

  //console.log(grossProfit);

  // ! final code for get pracentage
  // calculate pracentage
  const itemProfitPrest = () => {
    try {
      let pres = 100 - (calTp() / calValue()) * 100;
      return pres.toFixed(0);
    } catch (error) {
      setError(error.message);
      return 0; // or handle the error in a way that makes sense for your application
    }
  };

  useEffect(() => {
    const updateGrossProfitPercentage = () => {
      try {
        let pres = itemProfitPrest();

        if (isNaN(pres)) {
          return 0;
        }

        setGrossProfitPracentage(pres);
        setError(null); // Reset error if the update is successful
      } catch (error) {
        setError(error.message);
      }
    };
    updateGrossProfitPercentage();
  }, [quantities]);

  //! display msg on profit
  const displayMsg = () => {
    if (grossProfitPracentage <= 50) {
      return "Profit margin below 20%";
    }
  };

  // console.log(grossProfitPracentage);

  //!--------Laronda get cost -------------//

  useEffect(() => {
    if (!listItemNumber) {
      return;
    } else {
      const larondafindCost = async () => {
        const dataList = {
          listItemNumber,
        };

        try {
          await axios.post(`/getCost`, dataList).then((response) => {
            setItemCost(response.data);
          });
        } catch (error) {
          console.log(error);
        }
      };
      larondafindCost();
    }
  }, [listItemNumber]);

  //!----------Selected Entity cost ---------//
  useEffect(() => {
    const selectEntityfindCost = async () => {
      const dataList = {
        listItemNumber,
      };

      try {
        await axios.post(`/getCostSelectEntity`, dataList).then((response) => {
          setItemCostSelectEntity(response.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    selectEntityfindCost();
  }, [listItemNumber]);

  // ! todo Get updated itemlist data from database

  useEffect(() => {
    const getUpdateList = async () => {
      const list = {
        orderNo,
        listItemNumber,
      };
      try {
        if (!listItemNumber) {
          setReUpdateItem(/* initial value */);
        } else {
          await axios.post(`/getCreateItem`, list).then((response) => {
            setGetReUpdateItem(response.data);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUpdateList();
  }, [reUpdateItem, orderNo]);

  // ! get order all list items form database

  useEffect(() => {
    const allOrderItem = async () => {
      const list = {
        orderNo,
      };
      try {
        await axios.post("/getAllOrderItem", list).then((response) => {
          setAllOrderItem(response.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    allOrderItem();
  }, [reUpdateItem]);

  const totalPI = allOrderItem
    .reduce((sum, item) => sum + item.valueAED, 0)
    .toFixed(0);

  const totalGrossProfit = allOrderItem.reduce(
    (sum, item) => sum + item.grossProfit,
    0
  );
  const totalGrossProfitPracentage = (
    allOrderItem.reduce((sum, item) => sum + item.grossProfitPracentage, 0) /
    allOrderItem.length
  ).toFixed(2);

  const totalCTN = allOrderItem.reduce((sum, item) => sum + item.quantity, 0);

  const totalCBMAll = allOrderItem.reduce(
    (sum, item) => sum + item.totalCBM,
    0
  );

  const totalTPAll = allOrderItem
    .reduce((sum, item) => sum + item.totalTP, 0)
    .toFixed(0);

  const totalCMBRound = parseFloat(totalCBMAll.toFixed(0));

  const totalNP = totalGrossProfit - totalExpenses;

  const totalNpPracentage = () => {
    const totalpre = (totalNP / totalPI) * 100;
    return totalpre.toFixed(0);
  };

  const totalNpPracentageValue = totalNpPracentage();

  //check total CBM with contaner type

  const containerCMB = () => {
    const container20Ft = 24.5;
    const container40FtHC = 59.5;
    const container40Ft = 54.0;
    const container45Ft = 80.0;
    const truck = 63.0;

    let precentage = 0;

    if (containerType === "20 FT") {
      precentage = (totalCMBRound / container20Ft) * 100;
    } else if (containerType === "40 FT HC") {
      precentage = (totalCMBRound / container40FtHC) * 100;
    } else if (containerType === "40 FT") {
      precentage = (totalCMBRound / container40Ft) * 100;
    } else if (containerType === "45 FT") {
      precentage = (totalCMBRound / container45Ft) * 100;
    } else if (containerType === "Truck") {
      precentage = (totalCMBRound / truck) * 100;
    } else {
      return;
    }
    return precentage.toFixed(0);
  };

  const containerCMBTotal = parseFloat(containerCMB());

  //  console.log(totalGrossProfitPracentage);
  //console.log(allOrderItem);
  //console.log(totalCTN);
  //console.log(getReUpdateItem);
  // form data...

  useEffect(() => {
    itemData();
    //clientData();
  }, []);

  const userDepartment = user?.department;

  //! delete order

  const cancelOrder = async () => {
    const list = {
      orderNo,
    };
    try {
      await Promise.all([
        axios.post(`/cancel-order/${orderNo}`, list),
        axios.post(`/cancel-orderItems/${orderNo}`, list),
      ]);
      navigate("/account" || "/account/orders");
    } catch (error) {
      console.log(error);
    }
  };

  //! calculate Final status

  const finalStatusCal = () => {
    // console.log("confirmationStatus:", confirmationStatus);
    // console.log("financialConfirmation:", financialConfirmation);
    if (
      (confirmationStatus === "Plan" && financialConfirmation === "Approved") ||
      (confirmationStatus === "Plan" && financialConfirmation === "Pending") ||
      (confirmationStatus === "Plan" && financialConfirmation === "Rejected")
    ) {
      return "Plan";
    } else if (
      (confirmationStatus === "Tentative" &&
        financialConfirmation === "Approved") ||
      (confirmationStatus === "Tentative" &&
        financialConfirmation === "Pending") ||
      (confirmationStatus === "Tentative" &&
        financialConfirmation === "Rejected")
    ) {
      return "Tentative";
    } else if (
      confirmationStatus === "Firm" &&
      financialConfirmation === "Approved"
    ) {
      return "Firm";
    } else if (
      confirmationStatus === "Firm" &&
      financialConfirmation === "Pending"
    ) {
      return "Tentative";
    } else if (
      confirmationStatus === "Firm" &&
      financialConfirmation === "Rejected"
    ) {
      return "Tentative";
    }
  };

  useEffect(() => {
    const finalStatusValue = () => {
      const data = finalStatusCal();
      setFinalStatus(data);
      return;
    };
    finalStatusValue();
  }, [confirmationStatus, financialConfirmation]);

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="add_new_place">
          {/* <div>
            <h2> Order</h2>
          </div> */}
          <div className="___input">
            <div className="__id">
              Order No : <span>{orderNo}</span>
            </div>

            {userDepartment === "Sales" && (
              <button onClick={cancelOrder} className="__btn_">
                Cancel Order
              </button>
            )}
          </div>
          <form>
            <div className="__btn_wrap">
              {/* <select
                className="select_sec"
                id="conform"
                name="conform"
                value={conformStatus}
                required
                onChange={(e) => setConformStatus(e.target.value)}
              >
                color: rgb(180, 180, 180);
                <option style={{ fontSize: "12px" }}>SELECT TYPE</option>
                <option value="conform">Conform</option>
                <option value="forecast">Forecast</option>
              </select> */}

              <div className="__input_wrap">
                <button onClick={saveOrder} className="__btn">
                  Save Order
                </button>
              </div>
            </div>

            <div className="form_items_section">
              <div>
                <div className="order_summary">
                  <h3>Order Summary</h3>
                </div>
                <div className="form_data">
                  <div>
                    <h6>Sales Department -</h6>
                    <div className="data-filed">
                      <small>Client:</small>

                      <span>{client}</span>
                    </div>

                    <div className="data-filed">
                      <small>Country:</small>
                      <span>{arrayItem[2]}</span>
                    </div>
                    <div className="data-filed">
                      <small>Order Date</small>
                      <span>{orderDate}</span>
                    </div>

                    <div className="data-filed">
                      <small>Sales ETA:</small>
                      <span>{salesETA}</span>
                    </div>

                    <div className="data-filed">
                      <small>Confirmation Status:</small>
                      <span>{confirmationStatus}</span>
                    </div>
                    <div className="data-filed">
                      <small>Container Type:</small>
                      <span>{containerType}</span>
                    </div>
                    <br />

                    <h6>Account Department-</h6>
                    <div className="data-filed">
                      <small>Confirmation Status:</small>
                      <span>{financialConfirmation}</span>
                    </div>
                    <div className="data-filed">
                      <small>Commets:</small>
                      <span>{financialComments}</span>
                    </div>
                    <br />

                    <h6>Warehouse Department-</h6>
                    <div className="data-filed">
                      <small>Allocation:</small>
                      <span>{allocation}</span>
                    </div>
                    <div className="data-filed">
                      <small>Commets:</small>
                      <span>{warehouseComments}</span>
                    </div>
                    <br />
                  </div>
                  <div>
                    <h6>Procurement Department-</h6>
                    <div className="data-filed">
                      <small>Procurement ETA:</small>
                      <span>{procurementETA}</span>
                    </div>
                    <div className="data-filed">
                      <small>Commets:</small>
                      <span>{procurementComments}</span>
                    </div>
                    <br />
                    <h6>Production ETA-</h6>
                    <div className="data-filed">
                      <small>Procurement ETA:</small>
                      <span>{productionETA}</span>
                    </div>
                    <div className="data-filed">
                      <small>Commets:</small>
                      <span>{productionComments}</span>
                    </div>
                    <br />
                    <h6>Logistics Department-</h6>
                    <div className="data-filed">
                      <small>Incoterms:</small>
                      <span>{incoterms}</span>
                    </div>

                    <div className="data-filed">
                      <small>Dispatch ETA:</small>
                      <span>{dispatchETA}</span>
                    </div>
                    <div className="data-filed">
                      <small>Desitination ETA:</small>
                      <span>{desitinationETA}</span>
                    </div>
                    <div className="data-filed">
                      <small>Commets:</small>
                      <span>{logisticsComments}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item_section">
                <div className="order_summary">
                  <h3>Create Order</h3>
                </div>
                {userDepartment === "Sales" && (
                  <div className="item_section">
                    <div className="item_section_in">
                      <div style={{ display: "flex" }}>
                        <div>
                          <span onClick={toggleReadMoreLessF}>
                            {isShowMoreF ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </span>
                        </div>
                        <div>
                          <h3>Sales Department</h3>
                        </div>
                      </div>

                      <div className="__toggle_sec">
                        {isShowMoreF && (
                          <div>
                            <div className="__input" key={id}>
                              <label>
                                <p>Client</p>
                              </label>
                              <select
                                //required
                                value={client}
                                onClick={clientData} // Assuming this function is defined elsewhere
                                onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setClient(selectedOption);
                                }}
                              >
                                <option value="">Select an option</option>

                                {Array.isArray(selectedItem) ? (
                                  selectedItem.map((item, index) => (
                                    // Use item.name for the value and display of each option
                                    <option
                                      key={item.id}
                                      value={[
                                        item.name,
                                        item.no,
                                        item.country_Region_Code,
                                      ]}
                                    >
                                      {item.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">No items available</option>
                                )}
                              </select>
                            </div>

                            <div className="__input">
                              <label>
                                <p>Client No</p>
                              </label>
                              <input type="text" value={sales_code} />
                            </div>
                            <div className="__input">
                              <label>
                                <p>Country</p>
                              </label>
                              <input type="text" value={arrayItem[2]} />
                            </div>
                            <div className="__input">
                              <p>Order Date</p>
                              <input
                                //required
                                min={new Date().toISOString().split("T")[0]}
                                value={orderDate}
                                type="date"
                                onChange={(ev) => setOrderDate(ev.target.value)}
                              />
                            </div>

                            <div className="__input">
                              <p>Sales ETA</p>
                              <input
                                //required
                                min={new Date().toISOString().split("T")[0]}
                                value={salesETA}
                                type="date"
                                onChange={(ev) => setSalesETA(ev.target.value)}
                              />
                            </div>
                            <div className="__input">
                              <label>
                                <p>Confirmation Status</p>
                              </label>
                              <select
                                //required
                                id="resident"
                                name="resident"
                                value={confirmationStatus}
                                //required
                                onChange={(e) =>
                                  setConfirmationStatus(e.target.value)
                                }
                              >
                                color: rgb(180, 180, 180);
                                <option style={{ fontSize: "12px" }}>
                                  SELECT TYPE
                                </option>
                                <option value="Plan">Plan</option>
                                <option value="Tentative">Tentative</option>
                                <option value="Firm">Firm</option>
                              </select>
                            </div>

                            <div className="__input">
                              <label>
                                <p>Container Type</p>
                              </label>
                              <select
                                id="container"
                                name="container"
                                value={containerType}
                                required
                                onChange={(e) =>
                                  setContainerType(e.target.value)
                                }
                              >
                                color: rgb(180, 180, 180);
                                <option style={{ fontSize: "12px" }}>
                                  SELECT TYPE
                                </option>
                                <option value="20 FT">20 FT</option>
                                <option value="40 FT HC">40 FT HC</option>
                                <option value="40 FT">40 FT</option>
                                <option value="45 FT">45 FT</option>
                                <option value="Truck">Truck</option>
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {userDepartment === "Account" && (
                  <div className="item_section_in">
                    <div style={{ display: "flex" }}>
                      <div>
                        <span onClick={toggleReadMoreLessA}>
                          {isShowMoreA ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </span>
                      </div>
                      <div>
                        <h3>Account Department</h3>
                      </div>
                    </div>
                    <div className="__toggle_sec">
                      {isShowMoreA && (
                        <div className="__input">
                          <div className="__input">
                            <label>
                              <p>Confirmation Status</p>
                            </label>
                            <select
                              //required
                              name="resident"
                              value={financialConfirmation}
                              //required
                              onChange={(e) =>
                                setFinancialConfirmation(e.target.value)
                              }
                            >
                              color: rgb(180, 180, 180);
                              <option style={{ fontSize: "12px" }}>
                                SELECT TYPE
                              </option>
                              <option value="Approved">Approved</option>
                              <option value="Pending">Pending</option>
                              <option value="Rejected">Rejected</option>
                            </select>
                          </div>

                          <p>Commets</p>
                          <textarea
                            value={financialComments}
                            type="text"
                            onChange={(ev) =>
                              setFinancialComments(ev.target.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {userDepartment === "Warehouse" && (
                  <div className="item_section_in">
                    <div style={{ display: "flex" }}>
                      <div>
                        <span onClick={toggleReadMoreLessB}>
                          {isShowMoreB ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </span>
                      </div>
                      <div>
                        <h3>Warehouse Department</h3>
                      </div>
                    </div>
                    {isShowMoreB && (
                      <div className="__input">
                        <div className="__input">
                          <label>
                            <p>Allocation</p>
                          </label>
                          <select
                            required
                            name="resident"
                            value={allocation}
                            //required
                            onChange={(e) => setAllocation(e.target.value)}
                          >
                            color: rgb(180, 180, 180);
                            <option style={{ fontSize: "12px" }}>
                              SELECT TYPE
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        <p>Commets</p>
                        <textarea
                          value={warehouseComments}
                          type="text"
                          onChange={(ev) =>
                            setWarehouseComments(ev.target.value)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}

                {userDepartment === "Procurement" && (
                  <div className="item_section_in">
                    <div style={{ display: "flex" }}>
                      <div>
                        <span onClick={toggleReadMoreLessC}>
                          {isShowMoreC ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </span>
                      </div>
                      <div>
                        <h3>Procurement Department</h3>
                      </div>
                    </div>

                    {isShowMoreC && (
                      <div className="__input">
                        <p>Procurement ETA</p>
                        <input
                          value={procurementETA}
                          type="date"
                          min={new Date().toISOString().split("T")[0]}
                          onChange={(ev) => setProcurementETA(ev.target.value)}
                        />
                        <p>Commets</p>
                        <textarea
                          value={procurementComments}
                          type="text"
                          onChange={(ev) =>
                            setProcurementComments(ev.target.value)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}

                {userDepartment === "Production" && (
                  <div className="item_section_in">
                    <div style={{ display: "flex" }}>
                      <div>
                        <span onClick={toggleReadMoreLessD}>
                          {isShowMoreD ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </span>
                      </div>
                      <div>
                        <h3>Production Department</h3>
                      </div>
                    </div>

                    {isShowMoreD && (
                      <div className="__input">
                        <p>Production ETA</p>
                        <input
                          value={productionETA}
                          type="date"
                          min={new Date().toISOString().split("T")[0]}
                          onChange={(ev) => setProductionETA(ev.target.value)}
                        />
                        <p>Commets</p>
                        <textarea
                          value={productionComments}
                          type="text"
                          onChange={(ev) =>
                            setProductionComments(ev.target.value)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}

                {userDepartment === "Logistics" && (
                  <div className="item_section_in">
                    <div style={{ display: "flex" }}>
                      <div>
                        <span onClick={toggleReadMoreLessE}>
                          {isShowMoreE ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </span>
                      </div>
                      <div>
                        <h3>Logistics Department</h3>
                      </div>
                    </div>

                    {isShowMoreE && (
                      <div className="__input">
                        <div>
                          <label>
                            <p>incoterms</p>
                          </label>
                          <select
                            required
                            id="resident"
                            name="resident"
                            value={incoterms}
                            onChange={(e) => setIncoterms(e.target.value)}
                          >
                            color: rgb(180, 180, 180);
                            <option style={{ fontSize: "12px" }}>
                              SELECT TYPE
                            </option>
                            <option value="FOB">FOB</option>
                            <option value="EVW">EVW</option>
                            <option value="CRF">CRF</option>
                            <option value="CIF">CIF</option>
                          </select>
                        </div>

                        <div className="__input">
                          <p>Dispatch ETA</p>
                          <input
                            value={dispatchETA}
                            min={new Date().toISOString().split("T")[0]}
                            type="date"
                            onChange={(e) => setDispatchETA(e.target.value)}
                          />
                        </div>
                        <p>Desitination ETA</p>
                        <input
                          value={desitinationETA}
                          min={new Date().toISOString().split("T")[0]}
                          type="date"
                          onChange={(e) => setDesitinationETA(e.target.value)}
                        />
                        <p>Commets</p>
                        <textarea
                          value={logisticsComments}
                          type="text"
                          onChange={(e) => setLogisticsComments(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
          {/* todo add item section */}
          {userDepartment === "Sales" && (
            <form onSubmit={saveItemDB}>
              <div className="image_wrap">
                {spwPriceItem?.length > 0 &&
                  spwPriceItem?.map((item, index, imageIndex) => (
                    <div class="product-card" key={item._id}>
                      <div class="product-tumb">
                        <img
                          key={imageIndex}
                          src={`https://spw-api.notionsgroup.com/${item.images}`}
                          alt=""
                        />
                      </div>
                      <div class="product-details">
                        <input
                          className="qutInput"
                          disabled
                          type="text"
                          value={item.item_no}
                          class="product-catagory __input"
                        />
                        <h3>{item.description}</h3>
                        <div class="product-bottom-details">
                          <div class="product-links">
                            <div></div>
                            <button
                              type="submit"
                              onClick={() => handleAddButtonClick(item)}
                              className="btn_add"
                            >
                              <AddCircleIcon color="#7c5cfc" /> Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </form>
          )}

          <div className="item_list">
            <table>
              <tr>
                <th>Image</th>
                <th>Item No</th>
                <th>Description</th>
                <th>CBM</th>
                <th>UOM</th>
                <th>Unit Price</th>
                <th>Amount</th>
                <th>Value</th>
                <th>TP</th>
                <th>Cost</th>
                <th>Gross Profit</th>
                <th>Gross % </th>
                <th>Quantity</th>
                {userDepartment === "Sales" && (
                  <>
                    <th>Add Qut</th>
                    <th>Delete</th>
                  </>
                )}
              </tr>
              {itemTotalItem.map((item, index, imageIndex) => (
                <tr key={item._id}>
                  <td className="td_img">
                    <div className="td_in">
                      <img
                        key={imageIndex}
                        src={`https://spw-api.notionsgroup.com/${item.item_images}`}
                        alt="Product"
                      />
                    </div>
                  </td>
                  <td>
                    {" "}
                    <small> {item.item_item_no}</small>
                  </td>

                  <td>
                    {" "}
                    <small> {item.item_description}</small>
                  </td>
                  <td>
                    <small>{item.totalCBM}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.item_unit_of_measure_code}</small>
                  </td>
                  <td>
                    {" "}
                    <small>
                      {" "}
                      {item.item_unit_price} [
                      {item.item_currency_code === ""
                        ? "AED"
                        : item_currency_code}
                      ]
                    </small>
                  </td>
                  <td>
                    {" "}
                    <small>
                      {" "}
                      {item.amountOriginalPrice} [
                      {item.item_currency_code === ""
                        ? "AED"
                        : item_currency_code}
                      ]{" "}
                    </small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.valueAED} [AED]</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.totalTP}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.totalLrCost}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.grossProfit}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.grossProfitPracentage}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.quantity}</small>
                  </td>

                  {userDepartment === "Sales" && (
                    <td>
                      <small>
                        {" "}
                        <input
                          className="qutInput"
                          type="Number"
                          placeholder={item.quantity}
                          value={quantities[index]}
                          onChange={(e) =>
                            handleQuantityChange(
                              index,
                              parseInt(e.target.value)
                            )
                          }
                          onClick={() => getCostFromAPI(item.item_item_no)}
                        />
                      </small>
                    </td>
                  )}
                  {userDepartment === "Sales" && (
                    <td>
                      <button
                        className="btn_item"
                        onClick={() => deleteItem(item._id)}
                      >
                        <DeleteForeverIcon
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </table>
          </div>

          <div className="total">
            <div></div>

            {userDepartment === "Sales" && (
              <div className="other_input">
                <div className="other_input_in">
                  <div>
                    <p>Listing Cost</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={listingCost}
                    onChange={(e) => setListingCost(e.target.value)}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Rebate 10%</p>
                  </div>
                  <input
                    type="number"
                    min={1}
                    placeholder="0"
                    value={rabate}
                    onChange={(e) => setRabate(e.target.value)}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>POSM</p>
                  </div>
                  <input
                    type="number"
                    min={1}
                    placeholder="0"
                    value={posm}
                    onChange={(e) => setPosm(e.target.value)}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Special Displays Rental</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={rental}
                    onChange={(e) => setRental(e.target.value)}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Promoters Sampling</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={sampling}
                    onChange={(e) => setSampling(e.target.value)}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>FOC</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={foc}
                    onChange={(e) => setFoc(e.target.value)}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Price Discount</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Others</p>
                  </div>
                  <input
                    min={1}
                    type="number"
                    value={others}
                    onChange={(e) => setOthers(e.target.value)}
                  />
                </div>
              </div>
            )}

            <div className="total_wrap">
              <div className="other_total">
                <div className="other_total_title">
                  <p>PI Value</p>
                </div>
                <div className="other_total_num">
                  <p>{totalPI}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>GP AED</p>
                </div>
                <div className="other_total_num">
                  <p>{totalGrossProfit}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>GP %</p>
                </div>
                <div className="other_total_num">
                  <p>{totalGrossProfitPracentage}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>NP AED</p>
                </div>
                <div className="other_total_num">
                  <p>{totalNP}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>NP %</p>
                </div>
                <div className="other_total_num">
                  <p>{totalNpPracentageValue}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>Total CTN</p>
                </div>
                <div className="other_total_num">
                  <p>{totalCTN}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>Total CBM</p>
                </div>
                <div className="other_total_num">
                  <p>{totalCMBRound}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bar_con">
            <div style={appStyles}>
              <ProgressBar bgColor={"#ff9f00"} progress={progress} />
              <br />
            </div>
            <div className="truck_img">
              <img
                src={require("../../img/Truck with container.png")}
                alt="Product"
              />
            </div>
          </div>
          <div className="bar_info">
            <div className="__input">
              <label>
                <p>Container Type[CBM]</p>
              </label>
              <select
                id="container"
                name="container"
                value={containerType}
                required
                onChange={(e) => setContainerType(e.target.value)}
              >
                color: rgb(180, 180, 180);
                <option style={{ fontSize: "12px" }}>SELECT TYPE</option>
                <option value="20 FT">20 FT</option>
                <option value="40 FT HC">40 FT HC</option>
                <option value="40 FT">40 FT</option>
                <option value="45 FT">45 FT</option>
                <option value="Truck">Truck</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewOrder;

import React, { useContext, useReducer } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UserContext } from "../../UserContext";





const Navbar = () => {
    const { user } = useContext(UserContext);

  return (
    <div className="__fw" style={{ borderBottom: "1px solid #f0f0f0" }}>
      <div className="__mw">
        <header>
          <Link to={user ? "/account" : "/login"}>
            <div className="__n_item">
              <div className="_logo">
                <img src={require("../../img/logo.png")} />
              </div>
            </div>
          </Link>

          <Link to={user ? "/account" : "/login"}>
            <div className="__n_item">
              <div className="__n_login">
                <div> {!!user && <div>{user.name}</div>}</div>
                <AccountCircleIcon style={{ color: "gray" }} />
              </div>
            </div>
          </Link>
        </header>
      </div>
    </div>
  );
};

export default Navbar;

import React, { useContext, useEffect, useState } from "react";
import "./totalPTF.scss";
import axios from "axios";
import "./pieChatBox.scss";
import { IntlProvider } from 'react-intl'
import Box from "@mui/material/Box";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { startOfMonth, endOfMonth, isWithinInterval, parseISO } from "date-fns";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../UserContext.jsx";
import BigChatBox from "../bigChartBox/BigChatBox.jsx";
import { NumericFormat } from "react-number-format";
import {formatDate} from '../../pages/util/dateUtils.js'

const TotalPTF = () => {
  const navigate = useNavigate();
  const [ptfValue, setPtfValue] = useState([]);
  const { user } = useContext(UserContext);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [orders, setOrders] = useState([]);
  const [plan, setPlan] = useState([]);
  const [tentative, setTentative] = useState([]);
  const [firm, setFirm] = useState([]);
  const [oderInfo, setOderInfo] = useState([]);
  const [percentageDifference, setPercentageDifference] = useState(0);
  const userDepartment = user.department;
  // console.log(userDepartment);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userDepartment === "Sales") {
          const response = await axios.get("/user-places");

          setOderInfo(response.data);
          // const updatedPlan = response.data.filter(
          //   (item) => item.finalStatus === "Plan"
          // );
          // setPlan(updatedPlan);
          // const updatedTen = response.data.filter(
          //   (item) => item.finalStatus === "Tentative"
          // );
          // setTentative(updatedTen);
          // const updatedFirm = response.data.filter(
          //   (item) => item.finalStatus === "Firm"
          // );
          // setFirm(updatedFirm);

          const updatedOrder = response.data.filter((item) => item.orderDate);
          setOrders(updatedOrder);
        } else {
          const response = await axios.get("/getAllOrders");
          // const updatedPlan = response.data.filter(
          //   (item) => item.finalStatus === "Plan"
          // );
          // setPlan(updatedPlan);
          // const updatedTen = response.data.filter(
          //   (item) => item.finalStatus === "Tentative"
          // );
          // setTentative(updatedTen);
          // const updatedFirm = response.data.filter(
          //   (item) => item.finalStatus === "Firm"
          // );
          // setFirm(updatedFirm);
          setOderInfo(response.data);
          const updatedOrder = response.data.filter((item) => item.orderDate);
          setOrders(updatedOrder);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // ! FILTER ORDERS BY DAYS
  const months = [
    { value: "", label: "All Months" },
    { value: "0", label: "January" },
    { value: "1", label: "February" },
    { value: "2", label: "March" },
    { value: "3", label: "April" },
    { value: "4", label: "May" },
    { value: "5", label: "June" },
    { value: "6", label: "July" },
    { value: "7", label: "August" },
    { value: "8", label: "September" },
    { value: "9", label: "October" },
    { value: "10", label: "November" },
    { value: "11", label: "December" },
  ];

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  // todo Plan

  // ! Cal plan total PI

  const totalOfPlanPI = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Plan" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter((item) => item.orderDate && item.finalStatus === "Plan");
    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalPI, 0)
      .toFixed(0);
    return total;
  };
  const planTotalPI = Number(totalOfPlanPI());

  // ! Cal plan total PT

  const totalOfPlanTP = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Plan" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter((item) => item.orderDate && item.finalStatus === "Plan");

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalTPAll, 0)
      .toFixed(0);

    return total;
  };

  const planTotalTP = Number(totalOfPlanTP());

  // ! Cal plan total Profit

  const totalOfPlanProfit = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Plan" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter((item) => item.orderDate && item.finalStatus === "Plan");

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalNP, 0)
      .toFixed(0);

    return total;
  };

  const planTotalProfit = Number(totalOfPlanProfit());

  //! caiculate % of PLAN

  const planPrecent = () => {
    if (planTotalTP !== 0) {
      const difference = planTotalPI - planTotalTP;
      const percentage = (difference / planTotalTP) * 100;
      return percentage.toFixed(0);
      //  setPercentageDifference(percentage);
    } else {
      // Handle the case where cost is 0 to avoid division by zero
      // setPercentageDifference(0);
    }
  };
  const planPercentage = isNaN(Number(planPrecent()))
    ? 0
    : Number(planPrecent());

  const dataPlan = [
    { name: "Sales", value: planTotalPI, color: "#0088FE" },
    { name: "Cost", value: planTotalTP, color: "#FFBB26" },
    { name: "Net Pro ", value: planTotalProfit, color: "#00BCAD" },
    { name: "%", value: planPercentage, color: "#FF8042" },
  ];

  // todo Tentative

  // ! Cal plan total PI

  const totalOfTenPI = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Tentative" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter(
          (item) => item.orderDate && item.finalStatus === "Tentative"
        );

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalPI, 0)
      .toFixed(0);

    return total;
  };

  const tenTotalPI = Number(totalOfTenPI());

  // ! Cal plan total PT

  const totalOfTenTP = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Tentative" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter(
          (item) => item.orderDate && item.finalStatus === "Tentative"
        );

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalTPAll, 0)
      .toFixed(0);

    return total;
  };

  const tenTotalTP = Number(totalOfTenTP());

  // ! Cal plan total Profit

  const totalOfTenProfit = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Tentative" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter(
          (item) => item.orderDate && item.finalStatus === "Tentative"
        );

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalNP, 0)
      .toFixed(0);

    return total;
  };

  const tenTotalProfit = Number(totalOfTenProfit());

  //! caiculate % of TENTATIVE

  const tenPrecent = () => {
    if (tenTotalTP !== 0) {
      const difference = tenTotalPI - tenTotalTP;
      const percentage = (difference / tenTotalTP) * 100;
      return percentage.toFixed(0);
      //  setPercentageDifference(percentage);
    } else {
      // Handle the case where cost is 0 to avoid division by zero
      // setPercentageDifference(0);
    }
  };
  const tenPercentage = isNaN(Number(tenPrecent())) ? 0 : Number(tenPrecent());

  const dataTen = [
    { name: "Sales", value: tenTotalPI, color: "#0088FE" },
    { name: "Cost", value: tenTotalTP, color: "#FFBB26" },
    { name: "Net Pro", value: tenTotalProfit, color: "#00BCAD" },
    { name: "%", value: tenPercentage, color: "#FF8042" },
  ];

  // todo Firm

  // ! Cal plan total Firm

  const totalOfFirmPI = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Firm" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter((item) => item.orderDate && item.finalStatus === "Firm");

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalPI, 0)
      .toFixed(0);

    return total;
  };

  const firmTotalPI = Number(totalOfFirmPI());

  // ! Cal plan total Firm

  const totalOfFirmTP = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Firm" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter((item) => item.orderDate && item.finalStatus === "Firm");

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalTPAll, 0)
      .toFixed(0);

    return total;
  };

  const firmTotalTP = Number(totalOfFirmTP());

  // ! Cal plan total Firm

  const totalOfFirmProfit = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            order.finalStatus === "Firm" &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders.filter((item) => item.orderDate && item.finalStatus === "Firm");

    const total = filteredOrders
      .reduce((sum, item) => sum + item.totalNP, 0)
      .toFixed(0);

    return total.toLocaleString();
  };

  // const firmTotalProfit = Number(totalOfFirmProfit());

  const firmTotalProfit = parseInt(totalOfFirmProfit());

  //! caiculate % of TENTATIVE

  const firmPrecent = () => {
    if (firmTotalTP !== 0) {
      const difference = firmTotalPI - firmTotalTP;
      const percentage = (difference / firmTotalTP) * 100;
      return percentage.toFixed(0);
      //  setPercentageDifference(percentage);
    } else {
      // Handle the case where cost is 0 to avoid division by zero
      // setPercentageDifference(0);
    }
  };
  const firmPercentage = isNaN(Number(firmPrecent()))
    ? 0
    : Number(firmPrecent());

  const dataFirm = [
    { name: "Sales", value: firmTotalPI, color: "#0088FE" },
    { name: "Cost", value: firmTotalTP, color: "#FFBB26" },
    { name: "Net Pro", value: firmTotalProfit, color: "#00BCAD" },
    { name: "%", value: firmPercentage, color: "#FF8042" },
  ];

  // ! filter list as per orderDate date
  const filteredOrdersList = selectedMonth
    ? oderInfo.filter(
        (order) =>
          new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
      )
    : orders;

  //! total sales order

  const totalOrders = () => {
    const filteredOrders = selectedMonth
      ? orders.filter(
          (order) =>
            order.orderDate &&
            new Date(order.orderDate).getMonth() === parseInt(selectedMonth, 10)
        )
      : orders;
    const total = filteredOrders.length;
    return total;
  };

  const numTotalOrders = totalOrders();

  // todo list filter

  const [places, setPlaces] = useState([]);

  const handleCellClick = (params) => {
    const placesId = params.row.id;
    const url = `/place/${placesId}`;
    navigate(url);
  };

  // const orderDateFor = formatDate(orderDate);

  const columns = [
    { field: "skuId", headerName: "Order No", width: 200 },
    { field: "orderDate", headerName: "Order Date", width: 100 },
    { field: "client", headerName: "Client" },
    { field: "confirmationStatus", headerName: "Conf.Status" },
    { field: "salesETA", headerName: "Container Type" },
    {
      field: "country",
      headerName: "Country",
      width: 80,
    },
    {
      field: "financialConfirmation",
      headerName: "Incoterms",
    },
    {
      field: "allocation",
      headerName: "Allocation",
    },
    {
      field: "procurementETA",
      headerName: "Procurement ETA",
    },
    {
      field: "productionETA",
      headerName: "Production ETA",
    },
    {
      field: "containerType",
      headerName: "Container Type",
    },
    {
      field: "dispatchETA",
      headerName: "Dispatch ETA",
    },
    {
      field: "desitinationETA",
      headerName: "Desitination ETA",
    },
  ];

  const getRowClassName = (params) => {
    const order = orders.find((item) => item.id === params.columns.id);

    // Check if the order exists and has financialConfirmation equal to "Approved"
    if (order && order.financialConfirmation === "Approved") {
      return "highlighted-row";
    }

    return "";
  };


  return (
    <div className="total-con">
      {/* <BigChatBox/> */}
      <div className="total-in">
        <div className="total-wrap">
          <div className="total-head">
            <h3>PLAN </h3>
          </div>
          <div className="pieChartBox">
            <div className="chart">
              <ResponsiveContainer width=" 99%" height={200}>
                <PieChart>
                  <Tooltip
                    contentStyle={{ background: "white", borderRadius: "5px" }}
                  />
                  <Pie
                    data={dataPlan}
                    innerRadius={"60%"}
                    outerRadius={"90%"}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {dataPlan.map((item) => (
                      <Cell key={item.name} fill={item.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="options">
              {dataPlan.map((item) => (
                <div className="option" key={item.name}>
                  <div className="title">
                    <div
                      className="dot"
                      style={{ backgroundColor: item.color }}
                    />
                    <span>{item.name}</span>
                  </div>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="total-wrap">
          <div className="total-head">
            <h3>TENTATIVE</h3>
          </div>
          <div className="pieChartBox">
            <div className="chart">
              <ResponsiveContainer width=" 99%" height={200}>
                <PieChart>
                  <Tooltip
                    contentStyle={{ background: "white", borderRadius: "5px" }}
                  />

                  <Pie
                    data={dataTen}
                    innerRadius={"60%"}
                    outerRadius={"90%"}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {dataTen.map((item) => (
                      <Cell key={item.name} fill={item.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="options">
              {dataTen.map((item) => (
                <div className="option" key={item.name}>
                  <div className="title">
                    <div
                      className="dot"
                      style={{ backgroundColor: item.color }}
                    />
                    <span>{item.name}</span>
                  </div>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="total-wrap">
          <div className="total-head">
            <h3>FIRM</h3>
          </div>
          <div className="pieChartBox">
            <div className="chart">
              <ResponsiveContainer width=" 99%" height={200}>
                <PieChart>
                  <Tooltip
                    contentStyle={{ background: "white", borderRadius: "5px" }}
                  />
                  <Pie
                    data={dataFirm}
                    innerRadius={"60%"}
                    outerRadius={"90%"}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {dataFirm.map((item) => (
                      <Cell key={item.name} fill={item.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="options">
              {dataFirm.map((item) => (
                <div className="option" key={item.name}>
                  <div className="title">
                    <div
                      className="dot"
                      style={{ backgroundColor: item.color }}
                    />
                    <span>{item.name}</span>
                  </div>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="total-wrap">
          {/* <div className="total-head">
            <h3>DATE</h3>
          </div> */}
          {/* <div>
            <label>
              Filter by Month:
              <select value={selectedMonth} onChange={handleMonthChange}>
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
            </label>
          </div> */}
          <div className="month_btn">
            <div>
              <button
                className="btn_month"
                value={""}
                onClick={handleMonthChange}
              >
                All Months
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"0"}
                onClick={handleMonthChange}
              >
                January
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"1"}
                onClick={handleMonthChange}
              >
                February
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"2"}
                onClick={handleMonthChange}
              >
                March
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"3"}
                onClick={handleMonthChange}
              >
                April
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"4"}
                onClick={handleMonthChange}
              >
                May
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"5"}
                onClick={handleMonthChange}
              >
                June
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"6"}
                onClick={handleMonthChange}
              >
                July
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"7"}
                onClick={handleMonthChange}
              >
                August
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"8"}
                onClick={handleMonthChange}
              >
                September
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"9"}
                onClick={handleMonthChange}
              >
                October
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"10"}
                onClick={handleMonthChange}
              >
                November
              </button>
            </div>
            <div>
              <button
                className="btn_month"
                value={"11"}
                onClick={handleMonthChange}
              >
                December
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="amountSummary">
        <div className="amountSummary_in">
          <div className="amountSummary_in-head">
            <p>Total Salse Orders</p>
            <h1>{numTotalOrders}</h1>
          </div>
          <div className="amountSummary_color"></div>
          <div className="amountSummary_in-head">
            <p>Net Profit</p>
            <div className="num_in">
              <NumericFormat
                className="number_f"
                value={firmTotalProfit}
                allowLeadingZeros
                thousandSeparator=","
              />
              <div className="small_t">
                <small>AED</small>{" "}
              </div>
            </div>
          </div>
          <div className="amountSummary_color"></div>
          <div className="amountSummary_in-head">
            <p>Profit Percentage</p>
            <h1>
              {firmPercentage} <small>%</small>
            </h1>
          </div>
        </div>
      </div>
      <div class="box transaction-box">
        <div class="header-container">
          <div className="__place_in">
            <br />
            {/* <div className="__place_in_head">Sales Orders</div> */}
            <div className="all_place">
              <DataGrid
                key={filteredOrdersList.id}
                onCellClick={handleCellClick}
                rows={filteredOrdersList}
                getRowId={(row) => row.skuId}
                columns={columns}
                pageSize={5}
                // checkboxSelection
                getRowClassName={getRowClassName}
                over
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      //pageSize:
                    },
                  },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalPTF

import React from 'react'
import './sideNav.css'
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const SideNav = () => {

   
  return (
    <nav class="sidebar">
      <div>
        <ul class="side-nav">
          <span class="side-nav__header">Main Menu</span>
          <li class="side-nav__item side-nav__item-active">
            <HomeIcon />
            <span>Dashboard</span>
          </li>
          <br />
          <br />

          <span class="side-nav__header">Entity</span>

          <span>
            <span>
              <li class="side-nav__item">
                <small>Horizon Adhesive Labels LLC</small>
              </li>
            </span>
          </span>

          <span>
            <li class="side-nav__item">
              <small>La Ronda Emirates L.L.C</small>
            </li>
          </span>
          <Link class="side-nav__item" to={"/account/orders"}>
            {" "}
            <small>Notions Global Trading Co. LLC</small>{" "}
          </Link>

          <span>
            <li class="side-nav__item">
              <small>Notions Global Trading-Germany</small>
            </li>
          </span>
          <span>
            <li class="side-nav__item">
              <small>Notions Manufacturing LLC.</small>
            </li>
          </span>
          <span>
            <li class="side-nav__item">
              <small>Notions Trading LLC</small>
            </li>
          </span>
          <span>
            <li class="side-nav__item">
              <small>Star Food Industry</small>
            </li>
          </span>
        </ul>
      </div>

      <ul class="side-nav">
        <li class="side-nav__item last-item">Version V 01.1</li>
      </ul>
    </nav>
  );
}

export default SideNav

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import './register.scss'


const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState("")


  const navigate = useNavigate()

  const ragisterUser = async (ev) => {
    ev.preventDefault();

    try {
      await axios.post("/register", {
        name,
        email,
        password,
        department,
      });
      navigate("/login");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert(
          "Registration failed. Please check the provided information and try again."
        );
      } else if (error.response && error.response.status === 500) {
        alert("Server error. Please try again later.");
      } else {
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="login">
          <div className="_log_in">
            <div className="_in_h">
              <h1>Register</h1>
            </div>
            <form onSubmit={ragisterUser}>
              <input
                type="text"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder="your@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <select
                className="select_input"
                name="department"
                id="department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option style={{ fontSize: "12px" }}>SELECT DEP..</option>
                <option value="Sales">Sales</option>
                <option value="Account">Account</option>
                <option value="Warehouse">Warehouse</option>
                <option value="Procurement">Procurement</option>
                <option value="Production">Production</option>
                <option value="Logistics">Logistics</option>
              </select>
              <input
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button>Register</button>
              <div className="_rg_pg">
                <span>
                  Already a member? <Link to={"/login"}> Login</Link>{" "}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

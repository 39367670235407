import axios from "axios";
import React, { useEffect, useState } from "react";
import "./uploadImage.scss";
import { useParams } from "react-router-dom";

const UploadImage = () => {

  const [image, setImage] = useState([]);
  const [itemTotalItem, setItemTotalItem] = useState([]);
  const [item, setItem] = useState([]);
  const[seletItemCode,setSeletItemCode]=useState([])
  const [uploadImage,setUploadImage] = useState([]);

  
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const formData = new FormData();
    for (let i = 0; i < image.length; i++) {
      formData.append("image", image[i]);
    }
    await axios.post(`/upload/${seletItemCode}`, formData).then((response) => {
      setUploadImage(response.data);
    });
  } catch (error) {
    console.log(error);
  }
};

// console.log(uploadImage);

  const getAllItem = async () => {

    try {
      await axios.get("/getalldata").then((response) => {
        setItem(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="__fw">
      <div className="__mw">
        <div className="image_con">
          <form onSubmit={handleSubmit}>
            <select
              //disabled
              style={{ color: "black" }}
              id="selectField"
              required
              value={seletItemCode}
              onClick={getAllItem}
              onChange={(e) => {
                const selectedOption = e.target.value;
                setSeletItemCode(selectedOption);
              }}
            >
              <option value="" disabled>
                Select Description
              </option>
              {item?.length > 0 &&
                item?.map((id_item) => (
                  <option key={id_item?.id} value={[id_item?.item_no]}>
                    {id_item?.item_no}
                  </option>
                ))}
            </select>

            <input
              type="file"
              accept="image/*"
              name="image"
              //multiple
              // Use onChange event to capture selected files
              onChange={(e) => setImage(e.target.files)}
            />
            <button type="submit">Upload</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;

import React from 'react'
import './footer.scss'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="__fw" style={{ backgroundColor: "#F7F7F7 " }}>
      <div className="__mw">
        <div className="footer">
          <div className="footer-in">
            <small>Notions Group. Copyright © {currentYear} </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer

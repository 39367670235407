import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../UserContext";

const CreateOrderButton = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [uid,setUid]= useState()
  const name = user.name;

  // console.log(name);

  const generateSKU = () => {
    const letter = name.slice(0, 3).toUpperCase();
    const number = Date.now();
    //const formatted = number.toLocaleString().replace(/\D/g, "").slice(0, 6);
    const sku = "SPW" + "-" + letter + number;
    return sku;
  };
  const skuId = generateSKU();

  const createOrder = async () => {
    const orderInfo = {
      name,
      skuId,
    };

    try {
      const response = await axios.post(`/createorder`, orderInfo);
      const uid = response.data.skuId;

      if (uid) {
        setUid(uid);
        navigate(`/account/order/${uid}`);
      }
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <div>
      <div className="add_btn">
        <Link onClick={createOrder} className="__btn">
          {" "}
          Create New Order
        </Link>
      </div>
    </div>
  );
};

export default CreateOrderButton;

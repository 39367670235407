import axios, { all } from "axios";
import React, { useEffect, useState } from "react";
import "./getNavdata.scss";

const GetNavdata = () => {
  const [itemCard, setItemCard] = useState([]);
  const [cusData, setCusData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [laItemCard, setLaItemCard] = useState([]);
  const [apiData, setApiData] = useState([]);


  // item card end poing collect and seva in database
  const larondItemCard_get = () => {
    axios
      .get(
        "http://NGDX01UAT01.corp.laronda.com:5048/TEST/ODataV4/Company('La%20Ronda%20Emirates%20L.L.C')/Items_SPW",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setLaItemCard(response.data.value);
      });
  };
  // Save all data to the database
  const larondItemCard_save = async () => {
    try {
      for (const item of laItemCard) {
        const listdata = {
          description: item.Description,
          no: item.No,
          sales_Unit_of_Measure: item.Sales_Unit_of_Measure,
          unit_Cost: item.Unit_Cost,
        };
        await axios.post("/spwitem_laronda", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const larondItemCard_update = async () => {
    try {
      for (const item of apiData) {
        const listdata = {
          description: item.Description,
          no: item.No,
          sales_Unit_of_Measure: item.Sales_Unit_of_Measure,
          unit_Cost: item.Unit_Cost,
        };
        await axios.put("/spwitem", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // item card end poing collect and seva in database
  const spwItem_get = () => {
    axios
      .get(
        "http://NGDX01UAT01.corp.laronda.com:5048/TEST/ODataV4/Company('Notions%20Global%20Trading%20Co.%20LLC')/Items_SPW",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setItemCard(response.data.value);
      });
  };
  // Save all data to the database
  const spwItem_save = async () => {
    try {
      for (const item of itemCard) {
        const listdata = {
          description: item.Description,
          no: item.No,
          sales_Unit_of_Measure: item.Sales_Unit_of_Measure,
          unit_Cost: item.Unit_Cost,
        };
        await axios.post("/spwitem", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const spwItem_update = async () => {
    try {
      for (const item of apiData) {
        const listdata = {
          description: item.Description,
          no: item.No,
          sales_Unit_of_Measure: item.Sales_Unit_of_Measure,
          unit_Cost: item.Unit_Cost,
        };
        await axios.put("/spwitem", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  //   get Customer end pont

  const cusGetdata = () => {
    axios
      .get(
        "http://NGDX01UAT01.corp.laronda.com:5048/TEST/ODataV4/Company('Notions%20Global%20Trading%20Co.%20LLC')/CustomersSPW",
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setCusData(response.data.value);
      });
  };
  // Save all data to the database
  const couSaveAllData = async () => {
    try {
      for (const item of cusData) {
        const listdata = {
          no: item.No,
          name: item.Name,
          country_Region_Code: item.Country_Region_Code,
          salesperson_code: item.Salesperson_Code,
          salesperson_name: item.SalesPerson_Name,
        };
        await axios.post("/spwcus", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const cousUpdateData = async () => {
    try {
      for (const item of cusData) {
        const listdata = {
          no: item.No,
          name: item.Name,
          country_Region_Code: item.Country_Region_Code,
          salesperson_code: item.Salesperson_Code,
        };
        await axios.put("/spwcus", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  //   get price end pont

  const priceGetdata = async () => {
    await axios
      .get(
        "http://NGDX01UAT01.corp.laronda.com:5048/TEST/ODataV4/Company(%27Notions%20Global%20Trading%20Co.%20LLC%27)/SalesPrice",
        {
          withCredentials: true,
        }
      )

      .then((response) => {
        setPriceData(response.data.value);
      });
  };
  // Save all data to the database
  const priceSaveAllData = async () => {
    try {
      for (const item of priceData) {
        const listdata = {
          item_no: item.Item_No,
          sales_code: item.Sales_Code,
          unit_price: item.Unit_Price,
          unit_of_measure_code: item.Unit_of_Measure_Code,
          salesperson_code: item.Salesperson_Code,
          description: item.Description,
          max_relational_exch_rate_amount: item.Max_Relational_Exch_Rate_Amoun,
          currency_code: item.Currency_Code,
          qty_per_unit_of_measure: item.Qty_per_Unit_of_Measure,
          starting_date: item.Starting_Date,
          length:item.Length,
          width: item.Width,
          height:item.Height,
        };
        await axios.post("/spwprice", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const priceUpdateData = async () => {
    try {
      for (const item of priceData) {
        const listdata = {
          item_no: item.Item_No,
          sales_code: item.Sales_Code,
          unit_price: item.Unit_Price,
          unit_of_measure_code: item.Unit_of_Measure_Code,
          salesperson_code: item.Salesperson_Code,
          description: item.Description,
        };
        await axios.put("/spwprice", listdata);
      }
      console.log("All data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleGet = () => {
    spwItem_get();
    cusGetdata();
    priceGetdata();
    larondItemCard_get()
  };

  const handleUpdate = () => {
    "";
  };
  const handleSave = () => {
    Promise.all([priceSaveAllData(),  couSaveAllData()]);
    return;
  };

  return (
    <div className="list">
      <div>
        <button onClick={handleGet}>Get</button>
        <button onClick={handleUpdate}>Update Data</button>
        <button onClick={couSaveAllData}>save</button>
        {itemCard.map((item, index) => (
          <div style={{ padding: "20px" }}>
            <li key={index}>
              Description: {item.Description}, <br /> No: {item.No}, <br />{" "}
              Sales Unit of Measure: {item.Sales_Unit_of_Measure}, <br /> Unit
              Cost: {item.Unit_Cost}
            </li>
          </div>
        ))}
      </div>
      <div>
        {cusData.map((item, index) => (
          <div style={{ padding: "20px" }}>
            <li key={index}>
              No: {item.No}, <br /> Sales Unit of Measure:{" "}
              {item.Country_Region_Code}, <br /> Unit Cost: {item.Name}
            </li>
          </div>
        ))}
      </div>
      <div>
        {priceData.map((item, index) => (
          <div style={{ padding: "20px" }}>
            <li key={index}>
              Item_No: {item.Item_No}, <br /> Sales Code: {item.Sales_Code},{" "}
              <br /> Unit Price: {item.Unit_Price} <br /> Unit of Measure_Code:{" "}
              {item.Unit_of_Measure_Code} <br /> Salesperson{" "}
              {item.Salesperson_Code} <br /> {item.Description}
              <br />
              <br />
              {item.Max_Relational_Exch_Rate_Amoun}
              <br />
              {item.Currency_Code}
              <br />
              {item.Qty_per_Unit_of_Measure}
            </li>
          </div>
        ))}
      </div>
      <div>
        {laItemCard.map((item, index) => (
          <div style={{ padding: "20px" }}>
            <li key={index}>
              Description: {item.Description}, <br /> No: {item.No}, <br />{" "}
              Sales Unit of Measure: {item.Sales_Unit_of_Measure}, <br /> Unit
              Cost: {item.Unit_Cost}
            </li>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetNavdata;

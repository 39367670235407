import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./singalPlace.scss";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ProgressBar from "../../components/progressBar/ProgressBar";
import "../../pages/addNewPlace/addNewOrder.scss";
import { UserContext } from "../../UserContext";




const SingalPlace = () => {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const orderNo = id;
  const [place, setPlace] = useState([]);
  const userDepartment = user?.department;

  const [selectedDates, setSelectedDates] = useState([]);
  const [itemTotalItem, setItemTotalItem] = useState([]);
  const [createdItem, setCreatedItem] = useState([]);

  const localizer = momentLocalizer(moment);
  const DnDCalendar = withDragAndDrop(Calendar);

  useEffect(() => {
    const getItems = async () => {
      const list = {
        orderNo,
      };
      try {
        await axios.post("/findtotal", list).then((response) => {
          setItemTotalItem(response.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getItems();
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get(`/places/${id}`).then((response) => {
      setPlace(response.data);
    });
  }, [id]);

  //ProgressBar
  const appStyles = {
    width: 404,
    height: 200,
    marginLeft: 95,
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="singal_place">
          <div className="head">{/* <h1>{place.title}</h1> */}</div>
          <div className="headign">
            <strong>Order Number</strong>
            <br />
            <h2 style={{ color: "#7c5cfc" }}>{place.id}</h2>
          </div>

          <div className="form_data">
            <div>
              <h6>Sales Department</h6>
              <div className="data-filed">
                <small>Client:</small>

                <span>{place.client}</span>
              </div>

              <div className="data-filed">
                <small>Country:</small>
                <span>{place.arrayItem}</span>
              </div>

              <div className="data-filed">
                <small>Sales ETA:</small>
                <span>{place.salesETA}</span>
              </div>

              <div className="data-filed">
                <small>Confirmation Status:</small>
                <span>{place.confirmationStatus}</span>
              </div>
              <div className="data-filed">
                <small>Container Type:</small>
                <span>{place.containerType}</span>
              </div>
              <br />

              <h6>Account Department</h6>
              <div className="data-filed">
                <small>Confirmation Status:</small>
                <span>{place.financialConfirmation}</span>
              </div>
              <div className="data-filed">
                <small>Commets:</small>
                <span>{place.financialComments}</span>
              </div>
              <br />

              <h6>Warehouse Department</h6>
              <div className="data-filed">
                <small>Allocation:</small>
                <span>{place.allocation}</span>
              </div>
              <div className="data-filed">
                <small>Commets:</small>
                <span>{place.warehouseComments}</span>
              </div>
              <br />
            </div>
            <div>
              <h6>Procurement Department</h6>
              <div className="data-filed">
                <small>Procurement ETA:</small>
                <span>{place.procurementETA}</span>
              </div>
              <div className="data-filed">
                <small>Commets:</small>
                <span>{place.procurementComments}</span>
              </div>
              <br />
              <h6>Production ETA</h6>
              <div className="data-filed">
                <small>Procurement ETA:</small>
                <span>{place.productionETA}</span>
              </div>
              <div className="data-filed">
                <small>Commets:</small>
                <span>{place.productionComments}</span>
              </div>
              <br />
              <h6>Logistics Department</h6>
              <div className="data-filed">
                <small>Incoterms:</small>
                <span>{place.incoterms}</span>
              </div>

              <div className="data-filed">
                <small>Dispatch ETA:</small>
                <span>{place.dispatchETA}</span>
              </div>
              <div className="data-filed">
                <small>Desitination ETA:</small>
                <span>{place.desitinationETA}</span>
              </div>
              <div className="data-filed">
                <small>Commets:</small>
                <span>{place.logisticsComments}</span>
              </div>
            </div>
          </div>

          <div className="item_list">
            <table>
              <tr>
                <th>Image</th>
                <th>Item No</th>

                <th>Description</th>
                <th>CBM</th>
                <th>UOM</th>
                <th>Unit Price</th>
                <th>Amount</th>
                <th>Value</th>
                <th>TP</th>
                <th>Cost</th>
                <th>Gross Profit</th>
                <th>Gross % </th>
                <th>Quantity</th>
              </tr>

              {itemTotalItem.map((item, index, imageIndex) => (
                <tr key={item._id}>
                  <td className="td_img">
                    <div className="td_in">
                      <img
                        key={imageIndex}
                        src={`https://spw-api.notionsgroup.com/${item.item_images}`}
                        alt="Product"
                      />
                    </div>
                  </td>
                  <td>
                    {" "}
                    <small> {item.item_item_no}</small>
                  </td>

                  <td>
                    {" "}
                    <small> {item.item_description}</small>
                  </td>
                  <td>
                    <small>{item.totalCBM}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.item_unit_of_measure_code}</small>
                  </td>
                  <td>
                    {" "}
                    <small>
                      {" "}
                      {item.item_unit_price} [{item.item_currency_code}]
                    </small>
                  </td>
                  <td>
                    {" "}
                    <small>
                      {" "}
                      {item.amountOriginalPrice} [{item.item_currency_code}]{" "}
                    </small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.valueAED} [AED]</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.totalTP}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.totalLrCost}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.grossProfit}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.grossProfitPracentage}</small>
                  </td>
                  <td>
                    {" "}
                    <small> {item.grossProfitPracentage}</small>
                  </td>
                </tr>
              ))}
            </table>
          </div>

          {/* <div className="celander">
            <DnDCalendar
              defaultDate={moment().toDate()}
              defaultView="month"
              // events={events}
              localizer={localizer}
              resizable
              style={{ height: "50vh" }}
            />
          </div> */}
          <div className="total">
            <div></div>

            {userDepartment === "Sales" && (
              <div className="other_input">
                <div className="other_input_in">
                  <div>
                    <p>Listing Cost</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={place.listingCost}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Rebate 10%</p>
                  </div>
                  <input
                    type="number"
                    min={1}
                    placeholder="0"
                    value={place.rabate}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>POSM</p>
                  </div>
                  <input
                    type="number"
                    min={1}
                    placeholder="0"
                    value={place.posm}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Special Displays Rental</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={place.rental}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Promoters Sampling</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={place.sampling}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>FOC</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={place.foc}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Price Discount</p>
                  </div>
                  <input
                    min={1}
                    placeholder="0"
                    type="number"
                    value={place.discount}
                  />
                </div>
                <div className="other_input_in">
                  <div>
                    <p>Others</p>
                  </div>
                  <input min={1} type="number" value={place.others} />
                </div>
              </div>
            )}

            <div className="total_wrap">
              <div className="other_total">
                <div className="other_total_title">
                  <p>PI Value</p>
                </div>
                <div className="other_total_num">
                  <p>{place.totalPI}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>GP AED</p>
                </div>
                <div className="other_total_num">
                  <p>{place.totalGrossProfit}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>GP %</p>
                </div>
                <div className="other_total_num">
                  <p>{place.totalGrossProfitPracentage}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>NP AED</p>
                </div>
                <div className="other_total_num">
                  <p>{place.totalNP}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>NP %</p>
                </div>
                <div className="other_total_num">
                  <p>{place.totalNpPracentageValue}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>Total CTN</p>
                </div>
                <div className="other_total_num">
                  <p>{place.totalCTN}</p>
                </div>
              </div>
              <div className="other_total">
                <div className="other_total_title">
                  <p>Total CBM</p>
                </div>
                <div className="other_total_num">
                  <p>{place.totalCMBRound}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bar_con">
            <div style={appStyles}>
              <ProgressBar bgColor={"#ff9f00"} progress={place.totalCMBRound} />{" "}
              //! need to add progress
              <br />
            </div>
            <div className="truck_img">
              <img
                src={require("../../img/Truck with container.png")}
                alt="Product"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingalPlace;

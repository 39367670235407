import React, { useContext, useEffect } from "react";
// import { UserContext } from "../../UserContext";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import "./accountPage.scss";
import "./account.css";
import axios from "axios";
import PlacesPage from "../places/PlacesPage";
import SideNav from "../../components/sideNav/SideNav";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";
import TotalPTF from "../../components/totalPTF/TotalPTF";
import { UserContext } from "../../UserContext";

const AccountPage = () => {
  const { user, setUser } = useContext(UserContext);

  const [userOderData, setUserOderData] = useState([]);

  let { subpage } = useParams();
  if (subpage === undefined) {
    subpage = "profile";
  }

  const navigate = useNavigate();

  if (!user) {
    return <Navigate to={"/login"} />;
  }

  function linkClass(type = null) {
    let classes = "pro_link ";

    if (type === subpage || (subpage === undefined && type === "profile")) {
      classes += "active";
    }
    return classes;
  }

  async function logout() {
    await axios.post("/logout");
    navigate("/login");
    setUser(null);
  }

  // const getUserOrderInfo = async () => {
  //   const userId = {

  //   };
  //   await axios.post("/getuserOderdata", userId).then((response) => {
  //     setUserOderData(response.data);
  //   });
  // };
  // getUserOrderInfo();

  
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="accountpage">
          {subpage === "profile" && (
            <div className="__profile">
              <div>
                <div className="dashboard">
                  <div className="container">
                    <SideNav />
                    <main className="main-content">
                      <div className="btn_box">
                        <div className="btn_box_in">
                          <h2>
                            Welcome{" "}
                            <strong style={{ color: "#FFBB26" }}>
                              {user.name}
                            </strong>
                          </h2>
                        </div>
                        <div>
                          <button className="btn_logout" onClick={logout}>
                            Logout
                          </button>
                        </div>
                      </div>
                      <div className="bottom-container">
                        <div className="bottom-container__left">
                          {/* <div className="box spending-box">
                            <div className="header-container">
                              <BigChatBox />
                            </div>
                          </div> */}
                          <TotalPTF />
                          {/* <OrderHistory /> */}
                        </div>
                        {/* <div className="bottom-container__right">
                          <div className="box">
                            <div
                              className="data_con"
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <Calendar className="calaneder_con" />
                            </div>
                          </div>
                          <div class="box spending-box">
                            <div class="header-container">
                              <h3 class="section-header">Orders by category</h3>
                            </div>
                            <div class="pie-chart">
                              <PieChartBox />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="all_Places">
            {subpage === "places" && <PlacesPage />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
